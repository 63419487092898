<template>
  <div class="cart">
    <template v-if="!data.initCart">
      <div class="cart__loading">
        <loading size="small" />
      </div>
    </template>
    <template v-else>
      <div class="cart__header">
        <div class="cart__title"><h3 v-html="lang.title"></h3></div>
        <div
          v-if="useCart.cartItems > 0"
          class="cart__checkout cart__checkout--header flex desktop:hidden"
        >
          <button @click.prevent="checkout" class="btn__base btn__base--dark">
            {{ lang.checkout }}
          </button>
        </div>
      </div>
      <transition name="fade" mode="out-in">
        <div v-if="useCart.cartItems > 0">
          <!-- <p>{{ useCart.cartItems }}</p> -->
          <!-- <pre>{{ useCart.state.items }}</pre> -->
          <form
            class="cart__form"
            action="/cart"
            method="post"
            ref="form"
            novalidate
            noprefetch
          >
            <div class="cart__items">
              <cart-item
                v-for="item in useCart.state.items.items"
                :key="item.id"
                :item="item"
                :customer="props.customer"
                source="cart"
              />
            </div>

            <div class="cart__actions">
              <div class="cart__empty">
                <button
                  @click.prevent="handleEmptyCart"
                  class="btn__base btn__base--outline-light"
                >
                  <icon-trash />
                  <p>Empty cart</p>
                </button>
              </div>
              <div class="cart__actions__content">
                <div class="cart__actions__header">
                  <div>
                    <div class="cart__actions__fulfilment">
                      <p>Bill To: {{ props.customer.name }}</p>
                      <p>Deliver To: {{ props.customer.name }}</p>
                    </div>
                    <div class="cart__actions__reference">
                      <input
                        v-model="useCart.state.items.note"
                        @keyup="useCart.updateOrderRef()"
                        type="text"
                        name="note"
                        id="cart-note"
                        placeholder="Type Order Reference #"
                      />
                      <div class="cart__actions__tooltip">
                        <div>
                          <info-circle />
                          <div class="cart__actions__tooltip__text">
                            <p v-html="lang.tooltip"></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="cart__actions__subtotal">
                  <div>
                    <p v-if="!useCart.state.loading" class="accent">
                      Subtotal:
                      <span>
                        {{ money(subTotal) }}
                      </span>
                    </p>
                    <span v-else class="loading"></span>
                    <p v-if="!useCart.state.loading" class="accent">
                      GST: {{ gst(subTotal) }}
                    </p>
                    <span v-else class="loading"></span>
                  </div>
                </div>
                <div class="cart__actions__total">
                  <div>
                    <p v-if="!useCart.state.loading" class="accent">
                      Order total: {{ total(subTotal) }}
                    </p>
                    <span v-else class="loading"></span>
                  </div>
                </div>
                <template class="block desktop:hidden">
                  <div class="cart__actions__checkout">
                    <div>
                      <button
                        @click.prevent="checkout"
                        :disabled="
                          useCart.state.loading === true || !loggedIn
                        "
                        class="btn__base btn__base--dark"
                      >
                        {{ lang.checkout }}
                      </button>
                    </div>
                  </div>
                </template>
              </div>
              <div
                class="cart__checkout cart__checkout--actions hidden desktop:block"
              >
                <input
                  type="submit"
                  class="hidden"
                  name="checkout"
                  ref="submit_btn"
                />
                <button
                  @click.prevent="checkout"
                  :disabled="
                    useCart.state.loading === true || !loggedIn
                  "
                  type="submit"
                  class="btn__base btn__base--dark"
                >
                  {{ lang.checkout }}
                </button>
              </div>
            </div>
          </form>
        </div>
        <div v-else class="cart__empty__wrap">
          <p class="accent" v-html="lang.empty"></p>
        </div>
      </transition>
    </template>
  </div>
</template>

<script setup>
import { ref, reactive, onBeforeMount, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useCartStore } from '../../stores/cart';
import { getState, clearItems, updateNote } from '../../utils/cart';
import { windowRedirect } from '../../utils/helpers';
import CartItem from './CartItem.vue';
import { money, gst, total } from '../../utils/money';
import IconTrash from './icons/IconTrash.vue';
import IconExclamation from './icons/IconExclamation.vue';
import InfoCircle from './icons/InfoCircle.vue';
import Loading from './Loading.vue';

const useCart = useCartStore();

const submit_btn = ref(null);

const props = defineProps({
  cart: {
    type: Object,
    required: true,
  },
  customer: {
    type: Object,
    required: true,
  },
  settings: {
    type: Object,
    required: false,
  },
});

const lang = PS.Language.cart;

const data = reactive({
  updating: false,
  initCart: false,
});

const subTotal = computed(() => {
  let total = null;
  useCart.state.items.items.forEach((item) => {
    total = total + item.price * item.quantity;
  });
  return total;
});

const handleEmptyCart = async () => {
  useCart.state.loading = true;
  try {
    await clearItems();
    await useCart.loadCartItems();    
    await useCart.appendProductData();
  } catch (err) {
    throw err;
  }
  useCart.state.loading = false;
};

const loggedIn = computed(() => {
  return props.customer.logged_in && props.customer.company ? true : false;
});

const checkout = () => {
  submit_btn.value.click();
};

onBeforeMount(async () => {
  if (!props.customer.logged_in) {
    window.location.href = '/account/login';
  } else {
    await useCart.loadCartItems();
    await useCart.appendProductData();
    data.initCart = true;
  }
});
</script>
