<template>
  <div
    v-if="data.item && data.item.variants"
    class="cart__item"
  >
    <div class="cart__item__details">
      <a
        :href="data.item.url"
        class="cart__item__image"
        :aria-label="data.item.product_title"
      >
        <VImg
          :media="image"
          :fixed-size="imageSize"
          :alt="data.item.product_title"
        />
      </a>
      <div class="cart__item__content">
        <a :href="data.item.url" :aria-label="data.item.product_title">
          <p class="accent" v-text="data.item.title"></p>
        </a>
        <div class="cart__item__price">
          <p class="accent small">
            {{ money(props.item.price) }}<span> (Plus GST)</span>
          </p>
        </div>
        <div class="cart__item__codes">
          <p class="light small">Code: {{ data.item.variants.barcode }}</p>
          <p class="small">SKU: {{ data.item.variants.sku }}</p>
        </div>
      </div>
      <div class="cart__item__quantity">
        <product-quantity
          :quantity="data.item.quantity"
          :max_quantity="data.item.variants.inventory_quantity"
          :available="data.item.variants.available"
          source="cart-item"
          @updateQuantity="updateQuantity"
        />
      </div>
      <div class="cart__item__subtotal">
        <p class="accent">{{ money(itemSubtotal) }}<span> (Plus GST)</span></p>
      </div>
      <div class="cart__item__remove">
        <button @click.prevent="removeLineItem">
          <icon-close />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, onMounted, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useCartStore } from '../../stores/cart';
import { updateItem, removeItem } from '../../utils/cart';
import { money } from '../../utils/money';
import VImg from './VImg.vue';
import ProductQuantity from './ProductQuantity.vue';
import IconClose from './icons/IconClose.vue';

const useCart = useCartStore();
const { cartItems, loadCartItems, name, doubleCount, increment } =
  storeToRefs(useCart);

const props = defineProps({
  item: {
    type: Object,
    required: true,
    default: () => {},
  },
  updating: {
    type: Boolean,
    required: false,
    default: false,
  },
  customer: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['updateQty']);

const settings = PS.Settings;

const data = reactive({
  item: props.item,
});

const image = props.item.image
  ? {
      alt: null,
      aspect_ratio: 1,
      height: 400,
      id: '',
      media_type: 'image',
      position: 1,
      src: props.item.image,
      width: 400,
    }
  : {
      alt: null,
      height: 400,
      width: 400,
      id: null,
      position: 1,
      src: `https:${settings.product_fallback_image}`,
    };
const imageSize = '400x';

const itemSubtotal = computed(() => {
  return props.item.price * props.item.quantity;
});

const updateQuantity = async (event) => {
  try {
    data.item.quantity = event;
    await updateItem(props.item.key, {
      quantity: data.item.quantity
    });
    await useCart.loadCartItems();
  } catch (err) {
    throw err;
  }
};

const removeLineItem = async () => {
  await removeItem(props.item.key);
  await useCart.loadCartItems();
};
</script>
